import { Container, Row, Col } from 'react-bootstrap';

const Founder = (props) => {
  const { data } = props;
  return (
    <section className="founder">
      <Container>
        <h2 className="founder__title title-dark">{data.title}</h2>

        <div className="founder__wrapper">
          <Row>
            <Col className="col-md-5">
              <div className="founder__img">
                <img src={data.image} alt="" />
              </div>
            </Col>
            <Col className="col-md-5 align-self-center">
              <div className="founder__descr">
                <h3 className="founder__name">{data.name}</h3>
                <div className="founder__position">{data.position}</div>
                <ul className="founder__list">
                  {data.list.map((item, i) => {
                    return (
                      <li key={i} className="founder__list-item">
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <a href="" className="founder__link"></a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Founder;
