import { Container, Row, Col } from 'react-bootstrap';

const Status = () => {
  return (
    <section className="status">
      <Container>
        <Row>
          <Col className="col-md-8 offset-md-2">
            <div className="status__title title-dark">
              отмечай свой статус и получай рекомендации от проверенных экспертов
            </div>
            <div className="status__subtitle">
              Планируй беременность, готовься к родам или следи за здоровьем и развитием ребёнка с
              помощью MAMA PRO
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Status;
