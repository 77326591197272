import img1 from '../assets/img/numbers/numbers-1.svg';
import img2 from '../assets/img/numbers/numbers-2.svg';
import img3 from '../assets/img/numbers/numbers-3.svg';
import img4 from '../assets/img/numbers/numbers-arrow.svg';

export const dataNumbers = {
  title:
    'Электронный сертификат «Мы Ждём Ребёнка» принимается во всех женских консультациях Беларуси',
  imageArrow: img4,
  image: img2,
  items: [
    {
      image: img1,
      title: '9 000+',
      description: 'мам и пап проходят программу прямо сейчас',
    },
    {
      image: img2,
      title: '21 000+',
      description: 'мам и пап уже получили электронный сертификат',
    },
    {
      image: img3,
      title: '18 экспертов',
      description: 'топового уровня участвовали в записи программы',
    },
  ],
};
