// import img from "../assets/img/founder/founder.png";
import img from "../assets/img/founder/founder2.png";

export const dataFounder = {
  title: "Эксперты Мама, Папа, Пой",
  image: img,
  name: "Аксана Ковалева-Мусси",
  position: "Психотерапевт",
  list: [
    "Сертифицированный музыкальный терапевт (МТА)",
    "Музыкальный Терапевт (NMT-Fellow)",
    "Магистр музыки в области музыкальной терапии, магистр искусств в области психологии",
    "Член Академии Неврологической музыкальной терапии",
  ],
};
