import { Container, Row, Col } from 'react-bootstrap';

const Promo = (props) => {
  const { data } = props;
  return (
    <section className="promo">
      <Container className="promo__container">
        <Row>
          <Col className="col-md-6 offset-1 p-0 col-8">
            <h1 className="promo__title title-light">{data.title}</h1>
            <p className="promo__description mt-4">{data.subtitle}</p>
            <div className="promo__links mt-5">
              <a href="" className="promo__links-item ">
                {data.link1}
              </a>
              <a href="" className="promo__links-item ">
                {data.link2}
              </a>
            </div>
          </Col>
        </Row>
        <Col>
          <img src={data.image} alt="phone" className="promo__img" />
        </Col>
      </Container>
    </section>
  );
};

export default Promo;
