import img1 from '../assets/img/tabs/phone-1.png';
import img2 from '../assets/img/tabs/phone-2.png';
import img3 from '../assets/img/tabs/phone-3.png';
import { Tab1, Tab2, Tab3, CheckTab } from '../components/UI/Svg';

export const dataTabs = [
  {
    id: 0,
    icon: <Tab1 />,
    title: 'Планирую беременность',
    check: <CheckTab />,
    image: img1,
    description:
      'MAMA PRO поможет тебе эффективно спланировать беременность, заранее подготовит ко всему, что тебе предстоит, и поможет справиться с возможными трудностями',
  },
  {
    id: 1,
    icon: <Tab2 />,
    title: 'Жду ребёнка',
    check: <CheckTab />,
    image: img2,
    description:
      'В MAMA PRO можно узнать всё про восстановление после родов, правильный уход за ребёнком, грудное вскармливание, здоровье и развитие малыша',
  },
  {
    id: 2,
    icon: <Tab3 />,
    title: 'Уже родила',
    check: <CheckTab />,
    image: img3,
    description:
      'С MAMA PRO ты узнаешь, что делать во время беременности, как уменьшить риски осложнений беременности, что стоит учесть и как подготовиться к безопасным родам',
  },
];
