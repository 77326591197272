import img1 from '../assets/img/advices/phone-1.png';
import img2 from '../assets/img/advices/phone-2.png';
import img3 from '../assets/img/advices/phone-3.png';

export const dataAdvices = [
  {
    image: img1,
    title: 'получай Советы на каждый день',
    description:
      'Получай полезные советы во время беременности и развития ребёнка. Каждый день — что-то новое!',
  },
  {
    image: img2,
    title: 'Mama Pro учитывает твои интересы',
    description: 'Множество тем и категорий — выбери для себя самые актуальные!',
  },
  {
    image: img3,
    title: 'Отслеживай свой статус',
    description: 'У тебя всегда под рукой вся необходимая информация от проверенных экспертов',
  },
];
