import { Container, Row, Col } from 'react-bootstrap';

const Download = (props) => {
  const { data } = props;
  return (
    <section className="download">
      <Container>
        <div className="download__container">
          <h2 className="download__title title-light">{data.title}</h2>
          <div className="download__wrapper">
            <div className="download__links">
              {data.link1}
              {data.link2}
            </div>
            <div className="download__logo">{data.logo}</div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Download;
