import { FaHeadphones } from 'react-icons/fa';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Logo from '../../UI/Logo';
import Social from '../../social/Social';

const NavbarHeader = (props) => {
  const { scrolled } = props;
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar">
        <Navbar.Brand className="navbar__brand d-flex justify-content-center align-items-center gap-2">
          <Logo light={scrolled ? true : false} />
          {/* <FaHeadphones
            style={{ fontSize: '24px' }}
            className={`navbar__icon ${scrolled ? 'scrolled' : ''}`}
          /> */}
          {/* <span className={`navbar__logo ${scrolled ? 'scrolled' : ''}`}>Music</span> */}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-lg`}
          style={{ borderColor: 'white', color: 'white' }}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          className="navbar__offcanvas">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-lg`}
              className="d-flex justify-content-center align-items-center gap-2">
              <FaHeadphones style={{ fontSize: '24px' }} className="navbar__icon" />
              <span className="navbar__logo">Music</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="navbar__nav align-self-center">
              <Nav.Link href="#study" className={`navbar__nav-link ${scrolled ? 'scrolled' : ''}`}>
                STUDY
              </Nav.Link>
              <Nav.Link
                href="#partners"
                className={`navbar__nav-link ${scrolled ? 'scrolled' : ''}`}>
                PARTNERS
              </Nav.Link>
              <Nav.Link href="#about" className={`navbar__nav-link ${scrolled ? 'scrolled' : ''}`}>
                ABOUT US
              </Nav.Link>
            </Nav>
            <Nav className="d-flex align-items-center justify-content-end flex-grow-1 gap-3">
              <Social light={scrolled ? true : false} />
              <button className="navbar__btn">LOGIN</button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};

export default NavbarHeader;
