import img1 from '../assets/img/content/content-img1.svg';
import img2 from '../assets/img/content/content-img2.svg';
import img3 from '../assets/img/content/content-img3.svg';

export const dataContent = {
  title1: 'Контент создают',
  title2: 'доктора и эксперты',
  title3: 'медицинской сферы',
  description:
    'Самая полная библиотека видеоуроков и текстовых статей для подготовки к родительству',
  items: [
    {
      title: 'Советы',
      description: 'Короткие видео экспертов с быстрыми ответами на актуальные вопросы',
      link: 'выбрать категорию',
      image: img1,
    },
    {
      title: 'Курсы',
      description: 'Серия видео, в которых эксперт очень подробно разбирает важные темы и вопросы',
      link: 'выбрать категорию',
      image: img2,
    },
    {
      title: 'Посты',
      description: 'Полезные авторские статьи экспертов на актуальные темы',
      link: 'выбрать категорию',
      image: img3,
    },
  ],
};
