import imgLeft from '../assets/img/partners/partners.svg';
import imgSm1 from '../assets/img/partners/partners-small-1.svg';
import imgSm2 from '../assets/img/partners/partners-small-2.svg';
import imgSm3 from '../assets/img/partners/partners-small-3.svg';

export const dataPartners = {
  title: '«мы ждём ребёнка» - бесплатная программа подготовки к родам',
  imageLeft: imgLeft,
  images: [imgSm1, imgSm2, imgSm3],
};
